import React, { Component } from "react";
import _ from "lodash";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { GlobalState } from "../../types/globalState";
import {
  Button,
  Chip,
  createStyles,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Theme,
  WithStyles,
  TextField,
  Typography,
  IconButton,
  Dialog,
  Switch,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { SportsGolf } from "@material-ui/icons";
import BorderedBox from "../SharedComponents/BorderedBox";
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtils from "@date-io/date-fns";
import CompetitionActions from "../../actions/competitions";
import TournamentActions from "../../actions/tournaments";
import CourseActions from "../../actions/courses";
import GameActions from "../../actions/games";
import PaymentAction from "../../actions/payments";
import { switchComponent } from "../../actions/componentRouter";
import LinearProgress from "@material-ui/core/LinearProgress";
import { showSnackbar } from "../SharedComponents/Notifier";
import { Competition, IndexedCompetitions, PlayingFormats } from "../../types/competition";
import { ScoreCard } from "../../types/scoreCard";
import { IndexedTournaments, Tournament } from "../../types/tournament";
import { hideDialog, showDialog } from "../SharedComponents/Dialog";
import { hideAuxiliaryDialog } from "../SharedComponents/AuxiliaryDialog";
import SearchClub from "../Search/SearchClub";
import SearchCourse from "../Search/SearchCourse";
import { Views } from "../Dashboard";
import { Option } from "../SharedComponents/Picker";
import LeaderboardConfig from "../leaderBoard/LeaderboardConfig";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";
import { GameAdditionalOrganisersTable } from "./GameAdditionalOrganiserTable";
import { Rules, User, UserBrifObj, License } from "../../types/user";
import { AxiosError } from "axios";
import { Game, Leaderboard, PaymentRules, GameResultRules } from "../../types/game";
import GameNavigationDialog from "./GameNavigationDialog";
import GamePayment from "./GamePayment";
import ScoreCardsList from "./ScoreCardsList";
import GameRuleSelector from "./GameRuleSelector";
import BestHoles from "components/Competition/BestHoles";
import GameResult from "components/Game/GameResult";
import PresentationInputs, { DEFAULT_PRESENTATION_VALUES } from "components/SharedComponents/PesentationInputs";
import { HELP_VIDEOS } from "consts";
import Config from "config";
import { showAlertDialog } from "components/SharedComponents/AlertDialog";

interface Props extends WithStyles {
  insertGame(date: any, keepDialog?: boolean, callback?: any): void;
  onboardAccount(redirectUrl: string, callback?: any): void;
  joinGame(code: string, callback?: (error: AxiosError | null, data: Game[]) => void): void;
  updateGame(id: string, data: any, auxiliary?: boolean): void;
  updateGameRule(id: string, ruleId: string): void;
  getScoreCards(courseId: string, callback?: any): void;
  fetchCompetitionsList(
    page: number,
    limit: number,
    filter?: string,
    sort?: string,
    dir?: string,
    query?: string,
  ): void;
  fetchTournamentsList(page: number, limit: number, filter?: any, sort?: string, dir?: string, query?: string): void;
  switchComponent(view: any, props?: any): void;
  loading: boolean;
  competitions: Competition[];
  competitionsObj: IndexedCompetitions;
  tournaments: Tournament[];
  tournamentsObj: IndexedTournaments;
  scoreCards: ScoreCard[];
  edit?: any;
  copy?: any;
  course?: any;
  club?: any;
  user: any;
  onlyGameRule?: boolean;
  onlyPresentationConfig?: boolean;
  auxiliary?: boolean;
}
interface State {
  [index: string]: any;
  game: any;
  scoreCards: ScoreCard[];
  err_name: boolean;
  err_competition: boolean;
  err_scoreCards: boolean;
  err_tournament: boolean;
  err_code: boolean;
  err_msg_code: string;
  dialog: boolean;
  keepPlayers: boolean;
}

class GameSetup extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let game = {
      code: "",
      date: new Date(),
      endTime: new Date(),
      startTime: new Date(),
      club: props.club ? props.club : {},
      course: props.course ? props.course : {},
      scoreCards: [],
      scorers: {},
      competitionId: "",
      tournament: [],
      name: "",
      players: {},
      startingTees: [1],
      leaderboard: { ...DEFAULT_PRESENTATION_VALUES, scoresDisplayOrder: false },
      startSheet: DEFAULT_PRESENTATION_VALUES,
      isPaid: false,
      paymentRules: {},
      verifyQs: false,
    };
    if (props.edit) {
      game = {
        ...props.edit,
        leaderboard: { ...game.leaderboard, ...props.edit.leaderboard },
      };
      game.startSheet = props.edit.startSheet
        ? { ...game.startSheet, ...props.edit.startSheet }
        : DEFAULT_PRESENTATION_VALUES;
      game.competitionId = (props.edit.competition && props.edit.competition._id) || props.edit.competitionId;
      game.tournament = props.edit.tournaments.map((t: any) => t._id + "," + t.name);
      if (!game.paymentRules) game.paymentRules = {};
      if (game.verifyQs === undefined) game.verifyQs = false;
    } else if (props.copy) {
      game = {
        ...game,
        ...props.copy,
        competition: { _id: props.copy.competition._id },
        // players: {},
        scorers: {},
      };
    }

    this.state = {
      game,
      scoreCards: [],
      err_name: false,
      err_competition: false,
      err_scoreCards: false,
      err_tournament: false,
      err_maxPlayers: false,
      err_code: false,
      err_msg_code: "",
      dialog: false,
      keepPlayers: false,
      gamePrevName: game?.name,
    };

    if (!props.copy) {
      props.fetchCompetitionsList(1, 50);
      props.fetchTournamentsList(1, 50);
      props.getScoreCards(
        game.course._id,
        (err: any, scoreCards: ScoreCard[]) => !err && this.setState({ scoreCards }),
      );
    }
  }

  formValidation = () => {
    const { name, scoreCards, competitionId, maxPlayers } = this.state.game;
    const err_name: boolean = name.length < 2 || filter.isProfane(name);
    const err_scoreCards: boolean = Object.values(scoreCards).length === 0;
    const err_competition = competitionId.length < 3;
    const err_maxPlayers: boolean =
      !maxPlayers ||
      maxPlayers < 1 ||
      maxPlayers > 1000 ||
      (this.isLimitedGame(this.props.user, this.state.game) && maxPlayers > 4);
    this.setState({ err_name, err_scoreCards, err_competition, err_maxPlayers });
    return !(err_name || err_scoreCards || err_competition || err_maxPlayers);
  };

  componentDidMount() {
    if (this.isLimitedGame(this.props.user, this.state.game))
      this.setState({ game: { ...this.state.game, maxPlayers: 4 } });
  }

  _handleTextFieldChange = (value: string, name: string) => {
    this.setState({
      game: { ...this.state.game, [name]: value },
    });
  };

  _onCompetitionChanges = (selected: Option) => {
    const isMatchplay =
      this.props.competitions.findIndex(
        cmp => cmp._id === selected.id && cmp.playingRules.playingFormat === PlayingFormats.Matchplay,
      ) !== -1;

    this.setState({
      game: {
        ...this.state.game,
        competitionId: selected.id,
        gameResultRules: isMatchplay
          ? {
              toResult: true,
              toFinish: false,
              extraHoles: [1],
              toLastHole: false,
              highlightTarget: true,
            }
          : undefined,
      },
    });
  };

  _onAdditionalOrganisersChage = (organisers: UserBrifObj[]) => {
    this.setState({
      game: {
        ...this.state.game,
        organizers: organisers,
      },
    });
  };

  _onTournamentChanges = (event: any) => {
    this.setState({
      game: {
        ...this.state.game,
        tournament: event.target.value,
      },
    });
  };
  onTournamentRemoved = (value: string) => {
    const { tournament } = this.state.game;
    this.setState({
      game: {
        ...this.state.game,
        tournament: tournament.filter((x: string) => x != value),
      },
    });
  };
  _handleDateChange = (date: Date | null) => {
    if (date !== null) {
      this.setState({ game: { ...this.state.game, date } });
    }
  };

  _handleKeepPlayersChange = (keepPlayers: boolean) => {
    this.setState({ keepPlayers });
  };
  _handleStartTimeChange = (date: Date | null) => {
    if (date !== null) {
      this.setState({ game: { ...this.state.game, startTime: date } });
    }
  };
  _handleEndTimeChange = (date: Date | null) => {
    if (date !== null) {
      this.setState({ game: { ...this.state.game, endTime: date } });
    }
  };

  isLimitedGame = (user: User, game: any) => {
    if (user.rule === Rules.Player) {
      return true;
    } else if (
      user.rule === Rules.Organiser &&
      user.licenseType === License.ClubLicensed &&
      !(
        game.course.club.registered ||
        (user.organisingClub && user.organisingClub.filter(clubId => clubId === game.club._id).length > 0)
      )
    )
      return true;
    else if (user.rule === Rules.ClubManager && user.club._id !== game.club._id && !game.club.registered) {
      return true;
    }
    return false;
  };

  handleLeadeboardConfigChanged = (leaderboard: Leaderboard) => {
    this.setState({
      game: {
        ...this.state.game,
        leaderboard,
      },
    });
  };

  handleStartSheetConfigChange = (field: string, value: boolean | number) => {
    this.setState({
      game: {
        ...this.state.game,
        startSheet: { ...this.state.game.startSheet, [field]: value },
      },
    });
  };

  _handleClubSelection = (item: any) => {
    if (item) {
      this.setState({
        game: { ...this.state.game, club: item },
      });
    } else {
      this.setState({
        game: { ...this.state.game, club: {}, course: {} },
      });
    }
  };
  _handleCourseSelection = (item: any) => {
    let course: any = {};
    if (item) {
      course = { ...item };
      this.props.getScoreCards(
        course._id,
        (err: any, scoreCards: ScoreCard[]) => !err && this.setState({ scoreCards }),
      );
      delete course.club;
    }
    this.setState({
      game: { ...this.state.game, course },
    });
  };

  _handleScoreCardPicker = (item: ScoreCard) => {
    let tmp = [...this.state.game.scoreCards];

    this.setState({
      game: {
        ...this.state.game,
        scoreCards: tmp.some((x: any) => x._id === item._id)
          ? tmp.filter((x: any) => x._id != item._id)
          : tmp.concat(item),
      },
    });
  };

  _onPlayersListChanged = (players: any) => {
    this.setState({ game: { ...this.state.game, players } });
  };

  _closeDialog = () => {
    this.setState({ dialog: false });
  };

  _handleHoleClick = (hole: number, holesCount: number) => {
    // All button
    if (hole === -1) {
      const holes = Array.from({ length: holesCount }, (_, i) => i + 1);
      return this.setState({ game: { ...this.state.game, startingTees: holes } });
    }

    const values = this.state.game.startingTees || [];
    this.setState({
      game: {
        ...this.state.game,
        startingTees: values.includes(hole) ? values.filter((x: number) => x !== hole) : values.concat(hole),
      },
    });
  };

  _checkPayBeforeAdd = (confirmCallBack: VoidFunction) => {
    const { game, keepPlayers } = this.state;

    if (keepPlayers && game?.isPaid && game.paymentRules?.payBeforePlay) {
      showAlertDialog(
        "You are adding a players to the pay before play game. You confirm that payment has been made by other means.",
        "",
        confirmCallBack,
        // No
        () => {},
      );
    } else {
      confirmCallBack();
    }
  };

  _submitForm = () => {
    const { tournamentsObj, edit, copy, onlyGameRule } = this.props;

    if (onlyGameRule) {
      this.props.updateGameRule(edit._id, this.state.game.competitionId);
      return;
    }

    if (copy) {
      if (this.state?.gamePrevName?.trim() === this.state.game?.name?.trim()) {
        showSnackbar("Please change game name.");
        return;
      }
      this._checkPayBeforeAdd(() => {
        this.props.insertGame({
          ...this.state.game,
          copy: true,
          players: this.state.keepPlayers ? this.state.game?.players : {},
        });
      });
      return;
    }

    const { tournament } = this.state.game;
    const _game = {
      ...this.state.game,
      redirectUrl: window.location.href === "/" ? Config.PUBLIC_URL : window.location.href,
    };
    let game: any = (({
      name,
      date,
      organizers,
      state,
      leaderboard,
      maxPlayers,
      owner,
      startTime,
      course,
      competition,
      scoreCards,
      isPaid,
      paymentRules,
      startingTees,
      gameResultRules,
      verifyQs,
      startSheet,
      redirectUrl,
    }) => ({
      name,
      date,
      organizers,
      state,
      leaderboard,
      maxPlayers,
      owner,
      startTime,
      course,
      competition,
      scoreCards,
      isPaid,
      paymentRules,
      startingTees,
      gameResultRules,
      verifyQs,
      redirectUrl,
      startSheet,
    }))(_game);

    game.name = game.name.trim();
    game.competition = this.state.game.competition || { _id: this.state.game.competitionId };

    if (this.formValidation()) {
      game.tournaments = tournament.map((t: string) => {
        return tournamentsObj[t.split(",")[0]];
      });
      // if (game.isPaid) {
      //   showSnackbar("Payment feature is under development");
      // this.props.onboardAccount(game.redirectUrl, (err: any, res: any) => {
      //   if (!err) {
      //     if (res.onboardUrl) {
      //       window.open(res.onboardUrl, "_blank");
      //       showSnackbar("Please complete the payment process to continue");
      //       return;
      //     }
      //     if (edit) {
      //       this.props.updateGame(edit._id, game, this.props.auxiliary);
      //     } else {
      //       this.props.insertGame(game, true, (err: any, res: any) => {
      //         if (!err) {
      //           this.props.switchComponent(Views.DEFAULT);
      //           showDialog(<GameNavigationDialog gameId={res._id} />, "xl", false, true);
      //         }
      //       });
      //     }
      //   }
      // });
      // } else {
      if (edit) {
        this.props.updateGame(
          edit._id,
          { ...game, owner: typeof game.owner === "string" ? game.owner : game.owner._id },
          this.props.auxiliary,
        );
      } else {
        this.props.insertGame(game, true, (err: any, res: any) => {
          if (!err) {
            this.props.switchComponent(Views.DEFAULT);
            showDialog(<GameNavigationDialog gameId={res._id} />, "xl", false, true);
          }
        });
      }
      // }
    } else {
      showSnackbar("Form is not valid, check items");
    }
  };

  onPaidSwitchChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    // showSnackbar("Payment feature is under development");
    this.setState({ game: { ...this.state.game, isPaid: event.target.checked as boolean } });
  };

  handlePaymentRulesChanged = (paymentRules: PaymentRules) => {
    this.setState({
      game: { ...this.state.game, paymentRules },
    });
  };

  handleGameResultRulesChanged = (gameResultRules: GameResultRules) => {
    this.setState({
      game: {
        ...this.state.game,
        gameResultRules,
      },
    });
  };

  renderGameRulesSelector() {
    const { competitions, competitionsObj } = this.props;
    const { competitionId } = this.state.game;

    return (
      <GameRuleSelector
        competitionId={competitionId}
        competitions={competitions}
        competitionsObj={competitionsObj}
        onChange={this._onCompetitionChanges}
      />
    );
  }
  renderTournamentSelector() {
    const { tournaments, classes } = this.props;
    const { tournament } = this.state.game;
    const { err_tournament } = this.state;
    if (tournaments.length > 0) {
      return (
        <FormControl fullWidth margin={"dense"} error={err_tournament}>
          <div className={classes.inline}>
            {tournament.map((value: any) => {
              return (
                <Chip
                  className={classes.selectedTournament}
                  key={value.split(",")[0]}
                  label={value.split(",")[1]}
                  onDelete={() => this.onTournamentRemoved(value)}
                />
              );
            })}
          </div>
          <Select multiple value={tournament} onChange={this._onTournamentChanges}>
            {tournaments.map((item: Tournament) => (
              <MenuItem key={item._id} value={item._id + "," + item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    } else {
      return <p>No Tournament available</p>;
    }
  }
  renderActionButton() {
    const { classes, loading, auxiliary } = this.props;
    if (loading)
      return (
        <div className={classes.loadingContainer}>
          <LinearProgress color="primary" variant={"indeterminate"} />
        </div>
      );
    else
      return (
        <div className={classes.actionButtonContainer}>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"primary"}
            onClick={this._submitForm}
          >
            Save
          </Button>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"secondary"}
            onClick={auxiliary ? hideAuxiliaryDialog : hideDialog}
          >
            Cancel
          </Button>
        </div>
      );
  }
  renderCopyFormFields() {
    const { classes } = this.props;
    const { game, err_name, keepPlayers } = this.state;
    const { name, date } = game;

    return (
      <>
        <Typography variant={"h5"} className={classes.title}>
          Copy Game
        </Typography>
        <BorderedBox title={"Game name"} variant={"h6"}>
          <TextInput
            fullWidth
            placeholder={"Enter a name for this game"}
            style={{ margin: 0 }}
            error={err_name}
            captalize
            name={"name"}
            onChange={this._handleTextFieldChange}
            value={name}
          />
        </BorderedBox>
        <BorderedBox title={"Date & 1st Tee Time"} variant={"h6"}>
          <div className={classes.inline}>
            <MuiPickersUtilsProvider utils={DateUtils}>
              <KeyboardDateTimePicker
                id="date"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                style={{ margin: 0 }}
                value={date}
                onChange={this._handleDateChange}
                className={classes.datePicker}
              />
            </MuiPickersUtilsProvider>
          </div>
        </BorderedBox>
        <FormControlLabel
          className="policy-label"
          control={
            <Checkbox
              checked={keepPlayers}
              onChange={evt => this._handleKeepPlayersChange(evt.target.checked)}
              name="policy"
              color="secondary"
            />
          }
          label={"Should we keep players?"}
        />
        {this.renderActionButton()}
      </>
    );
  }

  renderFormContent() {
    const { classes, auxiliary } = this.props;
    const { game, err_name, err_scoreCards, err_maxPlayers, scoreCards } = this.state;
    const { name, date, organizers, maxPlayers } = game;
    const isLimitedGame = this.isLimitedGame(this.props.user, this.state.game);
    const isPlayer = this.props.user.rule === Rules.Player;
    const holesCount = game.course?.holesNumber || 18;

    if (game.course && game.course._id)
      return (
        <>
          <BorderedBox
            title={"Recent Scorecard"}
            videoIcon
            videoURL={HELP_VIDEOS.GAME_SETUP_SCORE_CARD}
            padding="none"
            variant={"h6"}
            error={err_scoreCards}
          >
            <ScoreCardsList
              cards={scoreCards}
              gameCards={game.scoreCards}
              onScoreCardSelected={this._handleScoreCardPicker}
            />
          </BorderedBox>
          <BorderedBox title={"Game name"} variant={"h6"}>
            <TextInput
              fullWidth
              placeholder={"Enter a name for this game"}
              style={{ margin: 0 }}
              error={err_name}
              captalize
              name={"name"}
              onChange={this._handleTextFieldChange}
              value={name}
            />
          </BorderedBox>
          <BorderedBox title={"Maximum Players"} variant={"h6"}>
            <TextField
              fullWidth
              type="number"
              style={{ margin: 0 }}
              error={err_maxPlayers}
              defaultValue={isLimitedGame ? 4 : null}
              name={"maxPlayers"}
              inputProps={{ min: 1, max: isLimitedGame ? 4 : 1000 }}
              onChange={e => this._handleTextFieldChange(e.target.value, e.target.name)}
              value={maxPlayers}
            />
          </BorderedBox>
          <BorderedBox title={"Date & 1st Tee Time"} variant={"h6"}>
            <div className={classes.inline}>
              <MuiPickersUtilsProvider utils={DateUtils}>
                <KeyboardDateTimePicker
                  id="date"
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  style={{ margin: 0 }}
                  value={date}
                  onChange={this._handleDateChange}
                  className={classes.datePicker}
                />
              </MuiPickersUtilsProvider>
            </div>
          </BorderedBox>

          <BorderedBox title={"QS Verification"} videoIcon videoURL={HELP_VIDEOS.GAME_SETUP_QS} variant={"h6"}>
            <Grid item md={10} xs={10}>
              <Typography variant="body1" gutterBottom>
                Quick Score verification
              </Typography>
            </Grid>
            <Grid item md={2} xs={2}>
              <Switch
                name="verifyQs"
                value={game.verifyQs}
                checked={game.verifyQs}
                onChange={e => {
                  this.setState({
                    game: {
                      ...this.state.game,
                      verifyQs: e.target.checked,
                    },
                  });
                }}
              />
            </Grid>
          </BorderedBox>

          <BorderedBox title={"Starting Tees"} variant={"h6"}>
            <div>
              <IconButton
                className={classes.teesBtn}
                onClick={() => {
                  this.setState({ dialog: true });
                }}
              >
                <SportsGolf />
              </IconButton>
            </div>

            <div className={classes.holesList}>
              {game.startingTees?.map((x: number) => (
                <Chip key={x} className={classes.hole} style={{ background: "#009e0f", color: "white" }} label={x} />
              ))}
            </div>
          </BorderedBox>

          <BorderedBox title={"Game rules"} variant={"h6"} error={this.state.err_competition}>
            {this.renderGameRulesSelector()}
          </BorderedBox>

          {game.gameResultRules && (
            <BorderedBox title={"Game Result"} variant={"h6"}>
              <GameResult
                holesCount={holesCount}
                gameResultRules={game.gameResultRules}
                onGameResultRulesChanged={this.handleGameResultRulesChanged}
              />
            </BorderedBox>
          )}

          <BorderedBox title={"Leaderboard"} videoIcon videoURL={HELP_VIDEOS.GAME_SETUP_LD} variant={"h6"}>
            <LeaderboardConfig
              leaderboardConfig={game.leaderboard}
              gameId={game._id}
              onLeaderboardConfigChanged={this.handleLeadeboardConfigChanged}
            />
          </BorderedBox>

          <BorderedBox title={"Start Sheet"} variant={"h6"}>
            <PresentationInputs
              fields={game.startSheet}
              handleInputChange={this.handleStartSheetConfigChange}
              onSwitchChange={this.handleStartSheetConfigChange}
            />
          </BorderedBox>

          <BorderedBox title={"Tournament"} videoIcon videoURL={HELP_VIDEOS.GAME_SETUP_TM} variant={"h6"}>
            {this.renderTournamentSelector()}
          </BorderedBox>
          {!isPlayer && (
            <BorderedBox
              title={"Payment"}
              videoIcon
              videoURL={HELP_VIDEOS.GAME_SETUP_PAYMENT}
              variant={"h6"}
              padding="none"
            >
              <GamePayment
                disabled={this.props.edit?.isPaid}
                isPaid={game.isPaid}
                paymentRules={game.paymentRules}
                onPaidSwitchChanged={this.onPaidSwitchChanged}
                onPaymentRulesChanged={this.handlePaymentRulesChanged}
              />
            </BorderedBox>
          )}
          <BorderedBox title={"Additional organisers"} videoIcon variant={"h6"} padding="none">
            {!this.props.edit ? (
              <>
                <p>Club managers will be added automatically as additional organisers</p>
                <GameAdditionalOrganisersTable
                  insideHandler={true}
                  organisers={organizers}
                  onChange={this._onAdditionalOrganisersChage}
                />
              </>
            ) : (
              <p>you can edit organisers through organisers column in the games list Table</p>
            )}
          </BorderedBox>
          {this.renderActionButton()}
          {/* Starting Tee Dialog */}
          <Dialog open={this.state.dialog} onBackdropClick={this._closeDialog}>
            <BestHoles
              holesCount={holesCount}
              selectedHoles={game.startingTees || []}
              onHoleClicked={(hole: number) => {
                this._handleHoleClick(hole, holesCount);
              }}
              onSubmit={this._closeDialog}
            />
          </Dialog>
        </>
      );
    else
      return (
        <div className={classes.actionButtonContainer}>
          <Button
            fullWidth
            className={classes.actionButton}
            variant={"contained"}
            color={"secondary"}
            onClick={auxiliary ? hideAuxiliaryDialog : hideDialog}
          >
            Cancel
          </Button>
        </div>
      );
  }

  renderClubSearch(club: any) {
    if (this.props.club) return null;
    return (
      <BorderedBox title={"Club"} variant={"h6"} padding={"none"}>
        <SearchClub dialog onClubSelected={this._handleClubSelection} defaultSelectedItem={club} />
      </BorderedBox>
    );
  }
  renderCourseSearch(course: any) {
    if (this.state.game.course.club?._id && !this.props.course)
      return (
        <BorderedBox title={"Course"} variant={"h6"} padding={"none"}>
          <SearchCourse
            clubId={this.state.game.course.club._id}
            onClubSelected={this._handleCourseSelection}
            defaultSelectedItem={course}
          />
        </BorderedBox>
      );

    return null;
  }
  render() {
    const { classes, copy, onlyGameRule, onlyPresentationConfig } = this.props;
    const { course } = this.state.game;

    if (onlyGameRule) {
      return (
        <div style={{ padding: 10 }}>
          <BorderedBox title={"Game rules"} variant={"h6"}>
            {this.renderGameRulesSelector()}
          </BorderedBox>
          {this.renderActionButton()}
        </div>
      );
    }

    if (onlyPresentationConfig) {
      return (
        <div style={{ padding: 10 }}>
          <BorderedBox title={"Leaderboard"} variant={"h6"}>
            <LeaderboardConfig
              gameId={this.state.game._id}
              leaderboardConfig={this.state.game.leaderboard}
              onLeaderboardConfigChanged={this.handleLeadeboardConfigChanged}
            />
          </BorderedBox>

          <BorderedBox title={"Start Sheet"} variant={"h6"}>
            <PresentationInputs
              fields={this.state.game.startSheet}
              handleInputChange={this.handleStartSheetConfigChange}
              onSwitchChange={this.handleStartSheetConfigChange}
            />
          </BorderedBox>
          {this.renderActionButton()}
        </div>
      );
    }

    return (
      <Paper className={classes.paper}>
        {copy ? (
          this.renderCopyFormFields()
        ) : (
          <>
            {this.props.club && this.props.course ? (
              <BorderedBox title={"Club/Course"} padding={"none"}>
                <p className={"text-center"}>
                  {this.props.club.name}/{this.props.course.name}
                </p>
              </BorderedBox>
            ) : null}
            {this.renderClubSearch(course.club)}
            {this.renderCourseSearch(course)}
            {this.renderFormContent()}
          </>
        )}
      </Paper>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    paper: {
      display: "block",
      position: "relative",
      left: "50%",
      transform: "translateX(-50%)",
      width: "auto",
      maxWidth: 500,
      minWidth: 360,
      backgroundColor: "rgba(255,255,552,0.85)",
      padding: theme.spacing(2),

      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
      },
    },
    title: {
      fontWeight: "bold",
      textAlign: "center",
    },
    inline: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      justifyContent: "center",
    },
    datePicker: {
      width: "100%",
      alignSelf: "center",
    },
    timePicker: {
      width: window.innerWidth < 360 ? "100%" : "50%",
    },
    borderBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    actionButton: {
      marginTop: theme.spacing(1),
    },
    loadingContainer: {
      width: "100%",
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    actionButtonContainer: {
      display: "flex",
    },
    selectedTournament: {
      margin: 2,
    },
    teesBtn: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",

      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
    },
    holesList: {
      textAlign: "left",
      padding: 5,
      display: "flex",
      flexWrap: "wrap",
      width: "100%",
    },
    hole: {
      marginRight: 2,
      marginTop: 2,
      width: 37,
      height: 37,
      borderRadius: "50%",

      [theme.breakpoints.down("xs")]: {
        width: 50,
        height: 50,
      },
    },
  });

const mapStateToProps = (state: GlobalState) => {
  const { user } = state.auth;
  const { loading } = state.general;
  const { competitions } = state.competitions;
  const { scoreCards } = state.scoreCards;
  const { tournaments } = state.tournaments;
  return {
    user,
    loading,
    competitions: competitions ? Object.values(competitions) : [],
    competitionsObj: competitions,
    scoreCards: scoreCards ? Object.values(scoreCards) : [],
    tournaments: tournaments ? Object.values(tournaments) : [],
    tournamentsObj: tournaments,
  };
};

export default connect(mapStateToProps, {
  insertGame: GameActions.insert,
  joinGame: GameActions.joinGame,
  updateGame: GameActions.update,
  updateGameRule: GameActions.updateGameRule,
  switchComponent: switchComponent,
  getScoreCards: CourseActions.getScoreCards,
  fetchCompetitionsList: CompetitionActions.fetch,
  fetchTournamentsList: TournamentActions.fetch,
  onboardAccount: PaymentAction.onBoardingAccount,
})(withStyles(styles)(GameSetup));
