import React from "react";
import { TableRow, TableCell } from "@material-ui/core";
import { GolfCourse } from "@material-ui/icons";
import { PayStatus } from "../../types/player";
import EmptyPositionRow from "./EmptyPositionRow";
import { PositionsInfo } from "./IndividualLeaderBoard";
import PlayerName from "components/SharedComponents/PlayerName";
import { getHandicapDisplay } from "utils";

interface Props {
  player: any;
  activeUserId: string;
  info: PositionsInfo;
  isStroke: boolean;
  isPaidGame: boolean;
  isFinalScore: boolean;
  isStandardGame: boolean;
  exactHandicaps: boolean;
  shouldPayBeforePlay: boolean;
  openScoreCardDialog(player: any): void;
  verified?: boolean;
  trRefs: React.RefObject<HTMLTableRowElement[]>;
  isOrganizer: boolean;
  widescreen: boolean;
  index: number;
  handicapAdjustment: boolean;
}

const renderPaymentStatus = (payStatus: PayStatus) => {
  if (payStatus === PayStatus.NOT_PAID)
    return (
      <div style={{ color: "red", fontSize: 18, float: "right" }}>
        <label>&#163;</label>
      </div>
    );

  if (payStatus === PayStatus.GUEST)
    return (
      <div style={{ color: "green", fontSize: 18, float: "right" }}>
        <label>G</label>
      </div>
    );
};

export default function LeaderboardPlayerRow(props: Props) {
  const {
    player,
    isStroke,
    activeUserId,
    isPaidGame,
    isFinalScore,
    isStandardGame,
    shouldPayBeforePlay,
    exactHandicaps,
    verified,
    isOrganizer,
    widescreen,
  } = props;
  const { showInfo, position, showInTwoSteps } = props.info;

  const empty = !showInfo && !isOrganizer;

  // console.log("position ", position);
  // console.log("empty ", empty);
  // console.log("showInfo", showInfo);
  // console.log("showInTwoSteps", showInTwoSteps);
  // console.log("**********************************");

  if (empty || (shouldPayBeforePlay && player.payStatus === PayStatus.NOT_PAID))
    return <EmptyPositionRow trRefs={props.trRefs} index={props.index} cells={isStroke ? 6 : 7} position={position} />;
  return (
    <TableRow
      onClick={() => (showInfo || isOrganizer ? props.openScoreCardDialog(player) : null)}
      style={{
        ...(player._id === activeUserId ? { background: "#cce8cf" } : {}),
        ...(!showInfo && isOrganizer ? { opacity: 0.5 } : {}),
      }}
      key={player._id}
      ref={el => props.trRefs.current && el && (props.trRefs.current[props.index] = el)}
    >
      <TableCell>
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          {position}

          {verified !== undefined ? <span style={{ color: verified ? "green" : "orange" }}>&#86;</span> : null}
        </span>
      </TableCell>
      {showInTwoSteps ? (
        <>
          <TableCell />
          <TableCell />
          <TableCell />
          {isStandardGame && <TableCell />}
        </>
      ) : (
        <>
          <TableCell>
            <GolfCourse color={"primary"} />
            {isPaidGame && renderPaymentStatus(player.payStatus)}
          </TableCell>
          <TableCell>
            <PlayerName
              lighter={!showInfo && isOrganizer && !widescreen}
              isGuest={player.guest}
              name={player.name}
              lastName={player.lastName}
            />
          </TableCell>
          <TableCell>{`${exactHandicaps ? `(${Number(player.hcp).toFixed(1)})` : ""}${getHandicapDisplay(
            player.hcp,
          )}`}</TableCell>
          {isStandardGame && <TableCell>{player.hci ? Number(player.hci).toFixed(1) : "0"}</TableCell>}
        </>
      )}
      {player.disqualified ? (
        <TableCell colSpan={4} align="center">
          DQ
        </TableCell>
      ) : (
        <>
          <TableCell>{player.gross}</TableCell>
          <TableCell>{player.nett}</TableCell>
          {!isStroke && <TableCell>{player.pts}</TableCell>}

          {isFinalScore && !props.handicapAdjustment ? null : (
            <TableCell>
              {isFinalScore
                ? player.newhcp
                : player.thru && (
                    <>
                      {player.thru}
                      <sup>{player.startingHole}</sup>
                    </>
                  )}
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  );
}
