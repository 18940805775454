import React from "react";
import { Link, useLocation } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

export const BackButton = () => {
  const location = useLocation();
  const isLeaderBoard = location.pathname.includes("leaderBoard");
  if (!isLeaderBoard) return null;

  return (
    <Link to="/" style={{ textDecoration: "none", color: "white" }}>
      <IconButton color="inherit" edge="end">
        {isLeaderBoard ? <CloseIcon /> : "Back"}
      </IconButton>
    </Link>
  );
};
