import React, { Component } from "react";
import { connect } from "react-redux";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import {
  Avatar,
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { CameraAlt } from "@material-ui/icons";
import Config from "../../config";
import UserAction from "../../actions/user";
import { switchComponent } from "../../actions/componentRouter";
import { GlobalState } from "../../types/globalState";
import { Gender, getRuleName, Rules, User } from "../../types/user";
import { buildFileSelector } from "../../utils/fileSelector";
import SearchClub from "../Search/SearchClub";
import BorderedBox from "../SharedComponents/BorderedBox";
import { Views } from "../Dashboard";
import { hideDialog } from "../SharedComponents/Dialog";
import { TextInput } from "../SharedComponents/TextInput";
import { filter } from "../../utils/profanityFilter";
import { validateEmail } from "../../utils/validator";
import { showSnackbar } from "../SharedComponents/Notifier";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateUtils from "@date-io/date-fns";
import { calculateAge } from "../../utils/date";
import HandicapActions from "../../actions/handicaps";

import { Handicap, IndexedHandicaps } from "types/handicap";
import { showPickerDialog } from "components/SharedComponents/PickerDialog";
import { hcpConvertor } from "utils/hcpConvertor";
import { getHandicapDisplay } from "utils";

interface Props extends WithStyles {
  updateMe(userInfo: any, callback?: any): void;
  switchComponent(view: any, props?: any): void;
  updateUser(userId: string, data: any, callback?: any): void;
  user: User;
  loading: boolean;
  otherUser?: boolean;
  fetchHnadicapLists(
    page: number,
    limit: number,
    filter?: any,
    sort?: string,
    dir?: string,
    query?: string,
    callback?: any,
  ): void;
  getHandicapInfo(id: string, callback?: any): void;
  updateHandicap(id: string, competitionId: string, hcp: number, playerId: string, callback?: any): any;
  handicaps: IndexedHandicaps;
}

interface StateUser extends User {
  stateHcp: string;
}

interface State {
  [index: string]: any;
  user: StateUser;
  imageFile?: any;
  err_name: boolean;
  err_lastName: boolean;
  err_username: boolean;
  err_email: boolean;
  err_postalCode: boolean;
  err_socialHcp: boolean;
  err_hcp: boolean;
  err_cdh: boolean;
  handicapId: string;
}

const fileSelector = buildFileSelector(false, ".jpg,.png,.jpeg");

class Profile extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.props.fetchHnadicapLists(
      1,
      50,
      { player: this.props.user._id },
      "player.lastName",
      undefined,
      undefined,
      (a: any, data: any) => {
        if (!data) {
          return;
        }
        const hcps = Object.values(data).find((ha: any) => ha.player._id === this.props.user._id) as any;
        this.props.getHandicapInfo(hcps?._id ?? "", () => {
          this.setState((state: State) => ({ handicapId: hcps?._id ?? "" }));
        });
      },
    );

    this.state = {
      user: { ...props.user!, stateHcp: getHandicapDisplay(props.user.hcp, false) },
      err_name: false,
      err_lastName: false,
      err_username: false,
      err_email: false,
      err_postalCode: false,
      err_socialHcp: false,
      err_hcp: false,
      err_cdh: false,
      handicapId: "",
    };
  }

  _onTextFieldChanged = (field: string) => (event: any) => {
    const value = event.target.value as string;
    this.setState((state: State) => ({ user: { ...state.user!, [field]: value ?? "" } }));
  };

  _handleTextInputChange = (value: string, name: string) => {
    this.setState((state: State) => ({ user: { ...state.user!, [name]: value ?? "" } }));
  };

  _onChangeGender = (event: any) => {
    if (event && event.target) {
      const gender = event.target.value;
      this.setState((state: State) => ({ user: { ...state.user!, gender } }));
    }
  };

  _onTitleChanged = (event: any) => {
    const name = event.target.name;
    this.setState((state: State) => ({
      user: {
        ...state.user!,
        [name]: event.target.value,
      },
    }));
  };

  _validatePostalCode(postalCode: string) {
    const separatedPC = postalCode?.split(" ");
    const firstPartRegex = /^[A-Z][A-HJ-Y]?[0-9][A-Z0-9]?$/;
    const lastPartRegex = /^(0[Aa]{2}|[0-9][A-Za-z]{2})$/i;
    if (separatedPC[0]?.match(firstPartRegex) && (!separatedPC[1] || separatedPC[1]?.length === 0)) {
      return true;
    }
    if (separatedPC[1]?.match(lastPartRegex)) {
      return true;
    }

    return false;
  }

  _validateWHSBoxes(cdhNumber: string, handicapIndex: string) {
    const hcpRegex = RegExp(/^\+?\d{1,2}(?:\.\d)?$/gm);
    let err = { hcp: false, cdh: false };
    if (cdhNumber.length > 0 || handicapIndex.length > 0) {
      if (cdhNumber.length === 0) {
        err.cdh = true;
      }
      if (handicapIndex.length === 0) {
        err.hcp = true;
      }
      if (!/^\d{1,10}$/.test(cdhNumber)) {
        err.cdh = true;
      }
      if (
        handicapIndex.length > 0 &&
        (parseInt(handicapIndex) < 0 || parseInt(handicapIndex) > 60 || !hcpRegex.test(handicapIndex))
      ) {
        err.hcp = true;
      }
    } else {
      err = { hcp: false, cdh: false };
    }
    return err;
  }
  _validateSocialHcp(socialHcp: string) {
    if (!Boolean(socialHcp)) {
      return true;
    }
    if (/^[0-9A-Za-z]{5}$/.test(socialHcp)) {
      return false;
    } else {
      return true;
    }
  }

  //WE Need form validation on profile
  _formValidation = () => {
    const { user } = this.state;
    const { username, name, lastName, email, postalCode, socialHcp, stateHcp, cdh } = user!;

    const err_name = filter.isProfane(name);
    const err_lastName = filter.isProfane(lastName);
    const err_username = username.length < 5 || filter.isProfane(username);
    const err_email = !validateEmail(email);
    const err_postalCode = !this._validatePostalCode(postalCode ?? "");
    const err_socialHcp = !this._validateSocialHcp(socialHcp);

    const { hcp: err_hcp, cdh: err_cdh } = this._validateWHSBoxes(cdh?.toString() ?? "", stateHcp ?? "");
    this.setState({ err_name, err_lastName, err_username, err_email, err_hcp, err_cdh, err_postalCode, err_socialHcp });

    return !(
      err_name ||
      err_lastName ||
      err_username ||
      err_email ||
      err_hcp ||
      err_cdh ||
      err_postalCode ||
      err_socialHcp
    );
  };

  _submitForm = () => {
    const { user, imageFile } = this.state;
    if (user) {
      const { name, lastName, gender, cdh, stateHcp, title, homeClub, dob, socialHcp, postalCode } = user!;
      const email = this.props.user!.email! !== user.email! ? user.email : undefined;
      const username = this.props.user!.username! !== user.username! ? user.username! : undefined;
      const dobTimestamp = dob ? Date.parse(dob.toString()) : null;
      if (this._formValidation()) {
        const numHcp = parseFloat(hcpConvertor(stateHcp));
        if (this.props.otherUser) {
          this.props.updateUser(
            user._id,
            {
              email: email?.trim(),
              username: username?.trim(),
              name: name?.trim(),
              hcp: numHcp,
              gender,
              cdh: cdh ?? "",
              socialHcp: Boolean(stateHcp) ? "" : socialHcp,
              postalCode,
              dob: dobTimestamp,
              image: imageFile,
              lastName: lastName?.trim(),
              title,
              homeClub,
            },
            (err: any) => {
              if (!err) hideDialog();
            },
          );
        } else {
          this.props.updateMe(
            {
              email: email?.trim(),
              username: username?.trim(),
              name: name?.trim(),
              hcp: numHcp,
              gender,
              cdh,
              socialHcp: Boolean(stateHcp) ? "" : socialHcp,
              postalCode,
              dob: dobTimestamp,
              image: imageFile,
              lastName: lastName?.trim(),
              title,
              homeClub,
            },
            (err: any) => {
              // if (!err) this.props.switchComponent(Views.DEFAULT);
            },
          );
        }
      } else {
        showSnackbar("Form is not valid, check items");
      }
    }
  };

  _handleFileSelector = (event: any) => {
    this.setState({ imageFile: event.target.files[0] });
  };

  _onClickAvatar = (event: any) => {
    event.preventDefault();
    fileSelector.click();
    fileSelector.onchange = this._handleFileSelector;
  };
  _onChangeHomeClub = (item: any) => {
    this.setState((state: State) => ({ user: { ...state.user!, homeClub: item } }));
  };
  _onEditClicked = (id: string, player: string, handicap: Handicap) => {
    if (!Boolean(id) || !Boolean(player)) {
      return;
    }
    showPickerDialog(
      "Handicap",
      "number",
      (hcp: string) => {
        this.props.updateHandicap(id, handicap.competitionId, parseFloat(hcpConvertor(hcp)), player);
      },
      0,
      null,
      null,
      false,
      true,
    );
  };

  getAvatar = (user: any) => {
    const { imageFile } = this.state;
    let avatar = require("../../assets/images/icons/question_mark_200px.png");

    if (imageFile) {
      avatar = URL.createObjectURL(imageFile);
    } else if (user.avatar) {
      avatar = Config.API_URL + user.avatar;
    } else if (user.gender === Gender.Men) {
      avatar = require("../../assets/images/icons/avatar_men.png");
    } else if (user.gender === Gender.Women) {
      avatar = require("../../assets/images/icons/avatar_women.png");
    } else if (user.rule === Rules.ClubManager) {
      avatar = require("../../assets/images/icons/house_100px.png");
    }
    return avatar;
  };
  renderActionButton = () => {
    const { loading } = this.props;
    if (loading) {
      return (
        <div style={{ width: "100%", height: 56, paddingTop: 28 }}>
          <LinearProgress />
        </div>
      );
    } else {
      return (
        <>
          <Button fullWidth color={"primary"} variant={"contained"} onClick={this._submitForm}>
            Save
          </Button>
          <Button
            fullWidth
            color={"secondary"}
            variant={"contained"}
            onClick={() => {
              if (this.props.otherUser) {
                hideDialog();
              } else {
                this.props.switchComponent(Views.DEFAULT);
              }
            }}
          >
            Back
          </Button>
        </>
      );
    }
  };
  render() {
    const { classes, user } = this.props;
    const {
      err_name,
      err_lastName,
      err_username,
      err_email,
      err_postalCode,
      err_socialHcp,
      err_cdh,
      err_hcp,
      handicapId,
    } = this.state;
    const {
      name,
      username,
      lastName,
      title,
      homeClub,
      cdh,
      dob,
      email,
      gender,
      stateHcp,
      rule,
      socialHcp,
      postalCode,
    } = this.state.user;
    let defaultHomeClub = homeClub || null;
    const handicap = this.props.handicaps[handicapId];
    return (
      <div className={classes.container}>
        <List
          style={{
            backgroundColor: "white",
          }}
        >
          <ListItem className={classes.centerListItem}>
            <Avatar
              className={classes.avatar}
              style={{ width: 100, height: 100, backgroundColor: "#FFF" }}
              src={this.getAvatar(user)}
            />
            <IconButton
              onClick={this._onClickAvatar}
              style={{ position: "absolute", bottom: 8, right: "39%", backgroundColor: "#c1c1c1" }}
              size={"small"}
            >
              <CameraAlt style={{ color: "#FFF", fontSize: 30, padding: 4 }} />
            </IconButton>
          </ListItem>
          <ListItem>
            <ListItemText primary={"Account Type"} secondary={getRuleName(rule)} />
          </ListItem>
          <ListItem>
            <TextInput
              type="text"
              label={"Username"}
              value={username}
              variant={"outlined"}
              fullWidth
              error={err_username}
              name={"username"}
              onChange={this._handleTextInputChange}
            />
          </ListItem>
          <ListItem>
            <TextInput
              type="text"
              label={"Email"}
              value={email}
              variant={"outlined"}
              fullWidth
              error={err_email}
              disableProfanityCheck
              name={"email"}
              onChange={this._handleTextInputChange}
            />
          </ListItem>
          <ListItem>
            <BorderedBox title={"Home Club"} variant={"h6"} padding={"none"} fullWidth>
              <SearchClub dialog defaultSelectedItem={defaultHomeClub} onClubSelected={this._onChangeHomeClub} />
            </BorderedBox>
          </ListItem>
          <ListItem>
            <div style={{ width: "100%", marginRight: 16 }}>
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup defaultValue={gender} aria-label="gender" onChange={this._onChangeGender} row>
                <FormControlLabel value="men" control={<Radio />} label="Male" />
                <FormControlLabel value="women" control={<Radio />} label="Female" />
              </RadioGroup>
            </div>
            <TextField fullWidth disabled type="text" label={"SURN"} variant="outlined" value={user.createDate ?? ""} />
          </ListItem>
          <ListItem>
            <FormControl fullWidth variant="outlined" style={{ marginRight: 16 }}>
              <InputLabel htmlFor={"title-label"}>Title</InputLabel>
              <Select
                label="Title"
                inputProps={{ name: "title", id: "title-label" }}
                value={title}
                fullWidth
                onChange={this._onTitleChanged}
              >
                <MenuItem value={"Mr"}>Mr</MenuItem>
                <MenuItem value={"Mrs"}>Mrs</MenuItem>
                <MenuItem value={"Miss"}>Miss</MenuItem>
              </Select>
            </FormControl>
            <TextInput
              type="text"
              label={"First name"}
              value={name}
              fullWidth
              variant={"outlined"}
              error={err_name}
              captalize
              name={"name"}
              onChange={this._handleTextInputChange}
            />
          </ListItem>
          <ListItem>
            <TextInput
              type="text"
              label={"Last name"}
              value={lastName}
              fullWidth
              style={{ marginRight: 16 }}
              variant={"outlined"}
              error={err_lastName}
              captalize
              name={"lastName"}
              onChange={this._handleTextInputChange}
            />
            <TextInput
              type="text"
              label={"Postcode"}
              value={postalCode}
              fullWidth
              variant={"outlined"}
              error={err_postalCode}
              captalize
              name={"postalCode"}
              onChange={this._handleTextInputChange}
            />
          </ListItem>
          <ListItem>
            <MuiPickersUtilsProvider utils={DateUtils}>
              <KeyboardDatePicker
                id="date"
                variant="dialog"
                label="Date of birth"
                format="dd/MM/yyyy"
                margin="normal"
                fullWidth
                style={{ marginRight: 16, marginBottom: "16px" }}
                inputVariant="outlined"
                value={dob || null}
                onChange={(date: Date | null) =>
                  //@ts-ignore
                  this.setState((state: State) => ({ user: { ...state.user!, dob: date } }))
                }
              />
            </MuiPickersUtilsProvider>
            <TextField
              type="number"
              label={"Age"}
              value={calculateAge(dob ? new Date(dob) : null)}
              style={{ width: "20%" }}
              disabled={true}
              variant={"outlined"}
            />
          </ListItem>
          <ListItem>
            <TextField
              type="text"
              label={"WHS Handicap Index"}
              value={stateHcp}
              fullWidth
              style={{ marginRight: 16 }}
              variant={"outlined"}
              error={err_hcp}
              inputProps={{ min: 0, max: 54 }}
              onChange={this._onTextFieldChanged("stateHcp")}
            />
            <TextField
              type="number"
              label={"WHS CDH No."}
              value={cdh}
              fullWidth
              error={err_cdh}
              variant={"outlined"}
              onChange={this._onTextFieldChanged("cdh")}
            />
          </ListItem>
          <ListItem>
            <TextField
              disabled={Boolean(stateHcp)}
              type="text"
              label={"Social H/Cap"}
              value={socialHcp ?? ""}
              style={{ width: "30%", marginRight: 16 }}
              variant={"outlined"}
              error={err_socialHcp}
              name={"socialHcp"}
              onChange={this._onTextFieldChanged("socialHcp")}
            />

            <FormControl fullWidth variant="outlined">
              <InputLabel htmlFor={"all-hcp's-label"}>All H/Caps & Indexes</InputLabel>
              <Select
                label="All H/Caps & Indexes"
                inputProps={{ name: "all-hcp's", id: "all-hcp's-label" }}
                value={""}
                fullWidth
                // onChange={() =>
                //   this._onEditClicked(handicap?._id ?? "", handicap?.player._id ?? "", handicap?.handicaps)
                // }
              >
                {handicap?.handicaps && handicap?.handicaps?.length > 0 ? (
                  handicap.handicaps
                    ?.sort((a: Handicap, b: Handicap) => b.lastUpdateDate - a.lastUpdateDate)
                    .map((h: Handicap) => {
                      return (
                        <MenuItem
                          key={h.lastUpdateDate}
                          value={h.lastUpdateDate}
                          style={{ display: "flex", alignItems: "center", justifyContent: "start", gap: "8px" }}
                        >
                          <Typography>{getHandicapDisplay(h.hcp)}.&nbsp;</Typography>
                          <Typography>{h.game}</Typography>
                        </MenuItem>
                      );
                    })
                ) : (
                  <MenuItem style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "8px" }}>
                    <Typography>None</Typography>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem>{this.renderActionButton()}</ListItem>
        </List>
      </div>
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    container: {
      minWidth: 300,
    },
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    centerListItem: {
      width: "100%",
      justifyContent: "center",
    },
    column: {
      flexDirection: "column",
    },
  });

const mapStateToProps = (state: GlobalState, props: any) => {
  const { user } = state.auth;
  const { handicaps } = state.handicaps;

  const { loading } = state.general;
  return {
    loading,
    handicaps: handicaps ?? [],
    user: props.user || user,
  };
};

export default connect(mapStateToProps, {
  updateMe: UserAction.updateMe,
  updateUser: UserAction.updateUser,
  fetchHnadicapLists: HandicapActions.fetch,
  getHandicapInfo: HandicapActions.getHandicapInfo,
  updateHandicap: HandicapActions.updateHandicap,
  switchComponent,
})(withStyles(styles)(Profile));
