import React, { useRef } from "react";
import _ from "lodash";
import {
  createStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  withStyles,
  WithStyles,
  Button,
} from "@material-ui/core";
import { Person, PersonAdd } from "@material-ui/icons";
import { Player, PayStatus } from "../../../types/player";
import { Game } from "../../../types/game";
import PlayerName from "components/SharedComponents/PlayerName";
import { sortByGroupName, isGameStandard } from "utils";
import { ADVERT_VALUES } from "consts";
import { useScroll } from "hooks/useScroll";
import { hideAuxiliaryDialog, showAuxiliaryDialog } from "components/SharedComponents/AuxiliaryDialog";
import ScorerGameSetup from "components/Dashboard/ScorerGameSetup";
import { useSelector } from "hooks/useSelector";
import { User } from "types/user";

interface Props extends WithStyles {
  game: Game;
  from: number;
  widescreen?: boolean;
  to?: number;
  onSwitchClick?: VoidFunction;
  isOrganiser: boolean;
  userState: User;
}

const _getTeamName = (players: Player[], payBeforePlay: boolean = false) => {
  const groups = _.groupBy(players, p => p.team?._id);

  return Object.values(groups)
    .map(ps =>
      ps
        .map(
          p =>
            `<span style="color: ${payBeforePlay && p.payStatus === PayStatus.NOT_PAID ? "red" : "#222"};">${
              p.lastName
            }</span>`,
        )
        .join("/ "),
    )
    .join(" and ");
};

const StartSheetTable = (props: Props) => {
  const { game, classes, widescreen, userState, onSwitchClick = () => {} } = props;
  const tableRef = useRef<HTMLDivElement>(null);
  const tableRowRef = useRef<HTMLTableRowElement>(null);

  useScroll(game.startSheet, tableRef.current, tableRowRef.current);

  const players = Object.values(game.players)
    .filter(p => !p.isTeamCard)
    .sort((a, b) => (`${a.name} ${a.lastName}` > `${b.name} ${b.lastName}` ? 1 : -1));
  const groups = _.groupBy(players, p => (p.group ? `${p.group.name}${p.group.startingHole || 1}` : ""));
  const userPlayer = players.find(prev => prev._id === userState._id);

  const playerGroupMembers = players.filter(prev => prev.group?.name === userPlayer?.group?.name || !prev.group?.name);

  const isTeamGame = game.competition.playingRules.playingAs === "team";
  const isCustomGame = !isGameStandard(game.competition.type);

  const hideGroupMembers = () => {
    hideAuxiliaryDialog();
  };

  const showGroupMembers = () => {
    if (!userPlayer || !userPlayer.group) return;

    showAuxiliaryDialog(
      <ScorerGameSetup
        routerProps={{ gameId: game._id, group: { name: userPlayer.group.name } }}
        groupMembers={playerGroupMembers}
        defaultMode="edit"
        showModeToggle={false}
        closeThisModal={hideGroupMembers}
      />,
      "xl",
      false,
    );
  };

  const ShowSheetAction = () => {
    if (props.isOrganiser) {
      return (
        <Button
          disableElevation
          variant="contained"
          className={classes.linkBtn}
          startIcon={<PersonAdd color="primary" />}
          onClick={onSwitchClick}
        >
          Players & Groups
        </Button>
      );
    } else {
      return (
        <Button
          disableElevation
          variant="contained"
          className={classes.linkBtn}
          startIcon={<Person color="primary" />}
          onClick={showGroupMembers}
          disabled={!props.onSwitchClick}
        >
          Group Members
        </Button>
      );
    }
  };

  return (
    <TableContainer ref={tableRef} className={`${classes.container} ${game.startSheet?.widescreen ? "wide" : ""}`}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <StyledTableCell align="left" style={{ width: 55 }}>
              Group
            </StyledTableCell>
            <StyledTableCell align="left" style={{ width: 40 }}>
              Tee
            </StyledTableCell>
            <StyledTableCell align="left" colSpan={10}>
              <div className={classes.flex}>
                <span>Players</span>
                {!widescreen && ShowSheetAction()}
              </div>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortGroups(Object.values(groups))
            .slice(props.from, props.to)
            .map((players, i) => (
              <StyledTableRow key={i} ref={i === 0 ? tableRowRef : null}>
                <TableCell style={{ whiteSpace: "nowrap" }}>{players[0].group && players[0].group.name}</TableCell>
                <TableCell>{(players[0].group && players[0].group.startingHole) || 1}</TableCell>
                {isTeamGame ? (
                  <TableCell
                    dangerouslySetInnerHTML={{ __html: _getTeamName(players, game.paymentRules?.payBeforePlay) }}
                  />
                ) : (
                  players
                    .filter(p => (game.paymentRules?.payBeforePlay ? p.payStatus !== PayStatus.NOT_PAID : true))
                    .map(player => (
                      <TableCell>
                        <PlayerName
                          isGuest={player.guest}
                          name={player.name}
                          lastName={player.lastName}
                          hci={isCustomGame && player.hcp ? player.hcp : player.hci}
                        />
                      </TableCell>
                    ))
                )}
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const sortGroups = (groups: Player[][]) => {
  return groups.sort((playersGroup1: Player[], playersGroup2: Player[]) => {
    const { startingHole: sth1 = 1, name: nm1 = "" } = playersGroup1[0]?.group || {};
    const { startingHole: sth2 = 1, name: nm2 = "" } = playersGroup2[0]?.group || {};

    if (!sth1 || !nm1) return 1;
    if (!sth2 || !nm2) return -1;
    // 1) By starting hole
    if (sth1 !== sth2) {
      return sth1 - sth2;
    }

    return sortByGroupName(nm1, nm2);
  });
};

const styles = (theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      height: "auto",

      "&.wide": {
        [theme.breakpoints.up("md")]: {
          margin: `${ADVERT_VALUES.HEIGHT}px 0`,
          paddingRight: `${Number.parseFloat(ADVERT_VALUES.SIDEBAR_WIDTH) - 1.5}vw`,
        },

        [theme.breakpoints.up("lg")]: {
          "& th, td.MuiTableCell-body ": {
            fontSize: "1.3rem",
          },
        },
        [theme.breakpoints.up("xl")]: {
          "& th, td.MuiTableCell-body ": {
            fontSize: "1.8rem",
          },
        },
      },
    },
    title: {
      margin: 4,
      flex: 1,
      fontSize: 16,
      fontWeight: "bold",
    },
    subtitle: {
      margin: 4,
      flex: 1,
      fontSize: 14,
      color: "#5c5c5c",
    },
    linkBtn: {
      backgroundColor: "#fff",
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  });

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default withStyles(styles)(StartSheetTable);
